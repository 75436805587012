import Masonry from "masonry-layout";
import ImagesLoaded from "imagesloaded";
import App from "../nad_app";

export class MasonryGrid {
  constructor(grid) {
    this.grid = grid;
    this.gridItems = grid.querySelectorAll(".js-many-gallery-grid-item");
    this.gridSizer = grid.querySelector(".js-many-gallery-grid-sizer");
    // this.gutterSizer = grid.querySelector(".js-many-gallery-gutter-sizer");

    this.gutterSize = {
      sm: 80,
      md: 40,
      lg: 40,
    };

    ImagesLoaded(this.gridItems, () => {
      this.gridItems.forEach((item) => {
        const img = item.querySelector("img");
        if (img.naturalWidth > img.naturalHeight) {
          item.classList.add("js-many-gallery-grid-item--landscape");
        }
      });

      this.masonryGrid = new Masonry(this.grid, {
        itemSelector: ".js-many-gallery-grid-item",
        columnWidth: ".js-many-gallery-grid-sizer",
        percentPosition: true,
        transitionDuration: 0,
        gutter: this.gutterSize[`${App.state.breakpoint}`],
      });
    });
  }
}

export const init = () => {
  App.addEventListener("pageLoad", (e) => {
    [...e.target.querySelectorAll(".js-many-gallery-grid")].map(
      (instance) => new MasonryGrid(instance)
    );
  });
};
