import app from "../nad_app";

class Video {
  constructor(container) {
    this.container = container;
    this.url = container.dataset.mediaUrl;

    this.options = {};
    this.options.background = container.dataset.background;
    this.options.autoplay = container.dataset.autoplay;
    this.options.disableLoop = container.dataset.disableLoop;
    this.options.showControls = container.dataset.showControls;
    this.options.mute = container.dataset.mute;

    this.videoObj = this.parse();
    this.create();
  }

  parse() {
    // - Supported YouTube URL formats:
    //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
    //   - http://youtu.be/My2FRPA3Gf8
    //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
    // - Supported Vimeo URL formats:
    //   - http://vimeo.com/25451551
    //   - http://player.vimeo.com/video/25451551
    // - Also supports relative URLs:
    //   - //player.vimeo.com/video/25451551

    this.url.match(
      /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\S+)?/
    );

    let type;
    if (RegExp.$3.indexOf("youtu") > -1) {
      type = "youtube";
    } else if (RegExp.$3.indexOf("vimeo") > -1) {
      type = "vimeo";
    }

    return {
      type,
      id: RegExp.$6,
    };
  }

  createYoutubeSrc() {
    const controls = this.options.showControls ? 1 : 0;
    const src = `//www.youtube.com/embed/${this.videoObj.id}?enablejsapi=1&controls=${controls}&showinfo=0&autohide=1&rel=0`;
    return src;
  }

  createVimeoSrc() {
    const background = this.options.background ? 1 : 0;
    const loop = this.options.disableLoop ? 0 : 1;
    const autoplay = this.options.autoplay ? 1 : 0;
    const mute = this.options.mute ? 1 : 0;
    const src = `//player.vimeo.com/video/${this.videoObj.id}?api=1&background=${background}&loop=${loop}&autoplay=${autoplay}&muted=${mute}`;
    return src;
  }

  create() {
    const iframe = document.createElement("iframe");
    iframe.classList.add("nad-iframe");
    iframe.setAttribute("frameborder", 0);
    iframe.setAttribute("tabindex", -1);

    if (this.videoObj.type === "youtube") {
      iframe.src = this.createYoutubeSrc();
    } else if (this.videoObj.type === "vimeo") {
      iframe.src = this.createVimeoSrc();
    } else {
      console.error("Video platform not supported.");
    }

    this.container.insertAdjacentElement("afterbegin", iframe);
  }
}

export const videos = {
  current: [],
};

export const init = () => {
  app.addEventListener("turbolinks:before-cache", {
    name: "video-clean-up",
    handler: () => {
      videos.current = [];
    },
  });

  app.addEventListener("turbolinks:load", {
    name: "video-initialization",
    handler: () => {
      const videoContainers = document.querySelectorAll(".js-nad-video");
      if (videoContainers.length === 0) {
        return;
      }

      videoContainers.forEach((container) => {
        const v = new Video(container);
        videos.current.push(v);
      });
    },
  });
};
