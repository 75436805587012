import app from "../nad_app";

export class ArtistGalleryHighlight {
  constructor(element) {
    this.element = element;
    this.galleryId = element.dataset.galleryId;
    this.artists = document.querySelectorAll(
      `.js-artist-gallery-${this.galleryId}`
    );
  }

  setup() {
    this.element.addEventListener("mouseenter", () => {
      this.element.classList.add("highlight");

      this.artists.forEach((artist) => {
        artist.classList.add("highlight");
      });
    });

    this.element.addEventListener("mouseleave", () => {
      this.element.classList.remove("highlight");

      this.artists.forEach((artist) => {
        artist.classList.remove("highlight");
      });
    });
  }
}

export const init = () => {
  app.addEventListener("pageLoad", () => {
    if (app.state.breakpoint !== "sm") {
      const highlightTriggers = document.querySelectorAll(
        ".js-artist-gallery-highlight"
      );
      highlightTriggers.forEach((element) => {
        const h = new ArtistGalleryHighlight(element);
        h.setup();
      });
    }
  });
};
