import interact from "interactjs";
import * as Util from "../utilities";

export class ObjectInteraction {
  static async postData(url = "", data = {}) {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "X-CSRF-Token": Util.getCSRFToken(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  constructor(object, gallery, type) {
    this.classes = {
      NEW_TO_GALLERY: "gallery__object--new",
    };

    this.object = object;
    this.type = type;
    this.objectId = object.getAttribute("data-object-id");
    this.url = `/database/${Util.toCamelCase(type)}s/${
      this.objectId
    }/save_position`;
    this.gallery = gallery;

    this.state = this.defaultState;

    this.initializeInteractions();
  }

  get defaultState() {
    return {
      pos_y: this.object.style.top,
      pos_x: this.object.style.left,
      width: this.object.style.width,
    };
  }

  initializeInteractions() {
    const position = {
      x: parseFloat(this.state.pos_x) || 0,
      y: parseFloat(this.state.pos_y) || 0,
    };

    const { gallery, object } = this;

    const update = {};
    const updateScaleAndPosition = this.updateScaleAndPosition.bind(
      this,
      update
    );

    const modifiers = [];

    if (this.type === "artwork") {
      modifiers.push(
        interact.modifiers.aspectRatio({
          ratio: "preserve",
        })
      );
    }

    interact(this.object)
      .origin(".js-gallery-editor__gallery")
      .draggable({
        listeners: {
          move(event) {
            const galleryRect = gallery.getBoundingClientRect();

            position.x += (event.dx / galleryRect.width) * 100;
            position.y += (event.dy / galleryRect.width) * 100;

            object.style.left = `${position.x}%`;
            object.style.top = `${position.y}%`;

            update.pos_x = `${position.x}%`;
            update.pos_y = `${position.y}%`;
            update.positioned = true;
          },
        },
      })
      .resizable({
        edges: { top: false, left: false, bottom: true, right: true },
        modifiers,
        listeners: {
          move(event) {
            const galleryRect = gallery.getBoundingClientRect();
            let { x, y } = event.target.dataset;

            x = (parseFloat(x) || 0) + event.deltaRect.left;
            y = (parseFloat(y) || 0) + event.deltaRect.top;

            const percentageWidth =
              (event.rect.width / galleryRect.width) * 100;
            const percentageHeight =
              (event.rect.height / galleryRect.height) * 100;

            Object.assign(event.target.style, {
              width: `${percentageWidth}%`,
              height: `${percentageHeight}%`,
            });

            update.width = `${percentageWidth}%`;
            update.height = `${percentageHeight}%`;
            update.positioned = true;

            Object.assign(event.target.dataset, { x, y });
          },
        },
      })
      .on(["dragend", "resizeend"], updateScaleAndPosition);
  }

  /* State management functions */
  update(update) {
    this.state = Object.assign(this.state, update);
    this.saveChanges();
  }

  updateScaleAndPosition(update) {
    this.update(update);
  }

  saveChanges() {
    const data = this.structureDataForParams(Util.toCamelCase(this.type));
    this.object.classList.remove(this.classes.NEW_TO_GALLERY);
    /* eslint-disable no-unused-vars */
    ObjectInteraction.postData(this.url, data).then((response) => {
      // JSON data parsed by `resonse.json()` call
    });
  }

  structureDataForParams(type) {
    const params = {};
    params[type] = this.state;
    return params;
  }
}
