import smoothscroll from "smoothscroll-polyfill";
import App from "../nad_app";

export class GalleryInfo {
  constructor() {
    this.element = document.querySelector(".js-gallery-info");
    this.scrollElement = document.querySelector(".js-carousel-scroll-spacer");
    this.headerGalleryInfo = this.element.querySelector(
      ".js-gallery-info-header-info"
    );
    this.headerGalleryCta = this.element.querySelector(
      ".js-gallery-info-header-cta"
    );
    this.headerGalleryClose = this.element.querySelector(
      ".js-gallery-info-close"
    );

    this.setup();
  }

  intro() {
    setTimeout(() => {
      this.scrollElement.scrollTo({
        top: 60,
        behavior: "smooth",
      });
    }, 1500);

    setTimeout(() => {
      this.scrollElement.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 2100);
  }

  attachHandlers() {
    const openHandler = function () {
      this.scrollElement.scrollTo({
        top: this.scrollElement.scrollHeight,
        behavior: "smooth",
      });

      this.headerGalleryCta.classList.add("hidden");
      this.headerGalleryClose.classList.remove("hidden");
      this.element.classList.add("is-open");
    };

    const closeHandler = function () {
      this.scrollElement.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      this.headerGalleryClose.classList.add("hidden");
      this.headerGalleryCta.classList.remove("hidden");
      this.element.classList.remove("is-open");
    };

    this.headerGalleryInfo.addEventListener("click", openHandler.bind(this));
    this.headerGalleryCta.addEventListener("click", openHandler.bind(this));
    this.headerGalleryClose.addEventListener("click", closeHandler.bind(this));
  }

  setup() {
    if (App.state.galleryIntroHasPlayed === false) {
      this.intro();
      App.state.galleryIntroHasPlayed = true;
    }

    this.attachHandlers();
  }
}

export const init = () => {
  App.addEventListener("pageLoad", () => {
    const galleryInfo = document.querySelector(".js-gallery-info");

    if (galleryInfo) {
      smoothscroll.polyfill(); // Safari does not support scrollTo smooth behavior

      // Note: because of the way the carousel is initialized
      // and how data is loaded per gallery, the actual call to
      // create a GalleryInfo is within populateGalleryInfo()
      // inside carousel.js
    }
  });
};
