import app from "../nad_app";

export class MobileHeader {
  constructor() {
    this.openButton = document.querySelector(".js-mobile-header-open");
    this.closeButton = document.querySelector(".js-mobile-header-close");
    this.openBottomButton = document.querySelector(
      ".js-mobile-header-bottom-open"
    );
    this.middleSection = document.querySelector(".js-mobile-header-middle");
    this.bottomSection = document.querySelector(".js-mobile-header-bottom");

    this.state = {
      bottomSectionOpen: false,
    };
  }

  setup() {
    this.openButton.addEventListener("click", () => {
      this.middleSection.classList.remove("hidden");
      this.openButton.classList.add("hidden");
      this.closeButton.classList.remove("hidden");
    });

    this.closeButton.addEventListener("click", () => {
      this.middleSection.classList.add("hidden");
      this.bottomSection.classList.add("hidden");
      this.openButton.classList.remove("hidden");
      this.closeButton.classList.add("hidden");
    });

    this.openBottomButton.addEventListener("click", (e) => {
      e.preventDefault();

      if (this.state.bottomSectionOpen) {
        this.bottomSection.classList.add("hidden");
        this.state.bottomSectionOpen = false;
      } else {
        this.bottomSection.classList.remove("hidden");
        this.state.bottomSectionOpen = true;
      }
    });
  }
}

export const init = () => {
  app.addEventListener("pageLoad", () => {
    if (app.state.breakpoint === "sm") {
      const m = new MobileHeader();
      m.setup();
    }
  });
};
