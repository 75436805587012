import { ObjectInteraction } from "./object_interaction";
import App from "../nad_app";

export class GalleryEditor {
  constructor(element, elements = {}) {
    this.setElements(element, elements);

    this.elements.artworks.forEach((item) => {
      new ObjectInteraction(item, this.elements.gallery, "artwork"); // eslint-disable-line no-new
    });

    this.elements.interviews.forEach((item) => {
      new ObjectInteraction(item, this.elements.gallery, "interview"); // eslint-disable-line no-new
    });

    this.elements.pullQuotes.forEach((item) => {
      new ObjectInteraction(item, this.elements.gallery, "galleryPullQuote"); // eslint-disable-line no-new
    });
  }

  setElements(element) {
    this.elements = {};
    this.elements.element = element;
    this.elements.gallery = element.querySelector(
      ".js-gallery-editor__gallery"
    );
    this.elements.artworks = element.querySelectorAll(
      ".js-gallery-editor__artwork"
    );
    this.elements.interviews = element.querySelectorAll(
      ".js-gallery-editor__interview"
    );
    this.elements.pullQuotes = element.querySelectorAll(
      ".js-gallery-editor__pull-quote"
    );
  }
}

export const init = () => {
  App.addEventListener("pageLoad", (e) => {
    [...e.target.querySelectorAll(".js-gallery-editor")].map(
      (instance) => new GalleryEditor(instance)
    );
  });
};
