import Flickity from "flickity";
import * as Util from "../utilities";
import App from "../nad_app";
import { ArtworkZoomModal } from "./artwork_zoom_modal";
import { GalleryInfo } from "./gallery_info";

/*
  This javascript provides an interface for Flickity to work in a
  Linked by Air type of way. It includes:
  - accessible buttons
  - screen reader announcements
  - turbolinks cacheing behavior
  This js should handle all of the default functionality for carousels.
  If you need to interact with a specific instance, you can either crawl
  the `Carousel.current` array, or you can use the static
  `getInstance` method. Otherwise, we provide static methods for performing
  actions on all active carousels.
  Example:
    import { Carousel } from "../scripts/generic_carousel";
    Carousel.current.forEach(c => c.next());
  Example:
    import { Carousel } from "../scripts/generic_carousel";
    const element = document.getElementById('some-id')
    const carousel = Carousel.getInstance(element);
    carousel.refreshLayout();
  Example:
    import { Carousel } from "../scripts/generic_carousel";
    Carousel.refreshAll();
  You may extend this class to provide custom functionality or controls.
  By default, instances of class extensions will still accumulate in the
  Carousel.current array.
  Certain elements are needed to build out functionality. You may pass
  them in via the `elements` argument, or use the default CSS class names.
  If using the latter path, make sure you elements exist within element.
  If the elements are found, functionality will be added. In that sense,
  they are all optional, but the you ought to include them for accessibility.
  See the `elements` option below.
  @param  {HTMLNode}                     element   The containing element
  @param  {object}                       options   Any options that should be passed to Flickity
  @param  {Carousel ~ Elements}   elements  Named references to elements that build out carousel functionality
  @typedef {Object} Carousel ~ Elements
  @property   {HTMLNode}    slideWrapper=".js-carousel-slide-wrapper"       The element that contains all the slides, which will be the target of the flickity initialization
  @property   {HTMLNode}    [previousControl=".js-carousel-previous"]       A button to select the carousel's previous slide
  @property   {HTMLNode}    [nextControl=".js-carousel-next"]               A button to select the carousel's next slide
*/

// TODO: Separate Artwork and Gallery carousels into separate classes that inherit from the Carousel class

export class Carousel {
  constructor(element, elements = {}) {
    this.classes = {
      CAPTION_HIDDEN: "js-carousel-caption--hidden",
      INFO_HIDDEN: "js-carousel-info--hidden",
      INFO_TRANSITIONING: "js-carousel-info--transitioning",
    };
    // ELEMENTS
    this.setElements(element, elements);
    this.setupAccessibility();

    this.setOptions();

    // if gallery carousel, resize gallery to be a factor of 2px;
    // TODO: find a better way to determine whether or not a gallery
    // if (this.infoWrapper) {
    //   this.resizeGallery();
    // }

    // FLICKITY
    this.initializeFlickity(this.options);

    // STATE
    this.state = this.defaultState;

    // EVENTS
    this.setupEvents();

    if (this.infoWrapper) {
      // Initialize Gallery Info
      this.populateGalleryInfo();
    }

    if (this.state.zoomable) {
      // Initize Artwork Zoom
      this.zoomModal = new ArtworkZoomModal(this);
    }

    // INITIAL RENDER
    this.update(this.state);

    // KEEP TRACK OF IT
    Carousel.current.push(this);
  }

  /* Constructor helpers (broken out so they can be overwritten) */
  setElements(element, elements) {
    this.element = element;
    this.carouselWrapper =
      elements.carouselWrapper ||
      element.querySelector(".js-carousel-flickity");
    this.previousControl =
      elements.previousControl ||
      element.querySelector(".js-carousel-previous");
    this.nextControl =
      elements.nextControl || element.querySelector(".js-carousel-next");
    this.captionControl =
      elements.captionControl || element.querySelector(".js-carousel-caption");
    this.captionWrapper =
      elements.captionWrapper ||
      element.querySelector(".js-carousel-caption-wrapper");
    this.infoWrapper =
      elements.infoWrapper ||
      element.querySelector(".js-carousel-info-wrapper");
    this.scrollSpacer =
      elements.scrollSpacer ||
      element.querySelector(".js-carousel-scroll-spacer");
  }

  setOptions() {
    this.options = {};

    // if page has a "gallery" url param, initialize flickity on the respective gallery
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get("gallery")) {
      this.options.initialIndex = urlParams.get("gallery") - 1;
    }

    const wrapDisabled =
      this.element.getAttribute("data-disable-wraparound") === "true";

    this.options.wrapAround = !wrapDisabled;
    this.options.cellAlignment = wrapDisabled ? "left" : "center";

    const desktopMode =
      App.state.breakpoint !== "sm" && App.state.breakpoint !== "md";

    const draggable =
      !desktopMode && this.element.getAttribute("data-draggable") === "true";

    this.options.draggable = draggable;
    this.options.freeScroll = draggable;
  }

  get defaultState() {
    return {
      index: this.options.initialIndex || 0,
      maxIndex: this.flickity.slides.length - 1,
      canGoNext: true,
      canGoPrevious: this.options.wrapAround,
      zoomLevel: 0,
      canZoomOut: false,
      canZoomIn: true,
      pointOfFocus: [0.5, 0.5],
      zoomable: this.element.getAttribute("data-zoomable") === "true",
      skipGalleryInfoIntro: false,
    };
  }

  initializeFlickity(options) {
    this.config = Object.assign(this.defaultConfig, options);
    this.flickity = new Flickity(this.carouselWrapper, this.config);
  }

  setupAccessibility() {
    // Announcements
    this.announcements = document.createElement("div");
    this.announcements.className = "visually-hidden js-carousel-announcements";
    this.announcements.setAttribute("aria-live", "polite");
    this.element.appendChild(this.announcements);

    // Control labels
    if (this.previousControl) {
      this.previousLabel = document.createElement("div");
      this.previousLabel.className = "visually-hidden";
      this.previousControl.appendChild(this.previousLabel);
    }

    if (this.nextControl) {
      this.nextLabel = document.createElement("div");
      this.nextLabel.className = "visually-hidden";
      this.nextControl.appendChild(this.nextLabel);
    }
  }

  setupEvents() {
    if (this.nextControl) {
      this.nextControl.addEventListener("click", () => {
        this.next();
      });
    }
    if (this.previousControl) {
      this.previousControl.addEventListener("click", () => {
        this.previous();
      });
    }
    if (this.captionControl) {
      this.captionControl.addEventListener("mouseenter", () => {
        this.showCaption();
      });
      this.captionControl.addEventListener("mouseleave", () => {
        this.hideCaption();
      });
    }
    // if (this.zoomInControl && this.state.zoomable) {
    //   this.zoomInControl.addEventListener("click", () => {
    //     this.handleZoomIn();
    //   });
    // }
    // if (this.zoomOutControl && this.state.zoomable) {
    //   this.zoomOutControl.addEventListener("click", () => {
    //     this.handleZoomOut();
    //   });
    // }

    // because of pointer events and context stacking on the gallery, we have to force the scroll behavior of the gallery info
    if (this.infoWrapper) {
      this.carouselWrapper.addEventListener("wheel", (e) => {
        this.scrollInfoWrapper(e);
      });
    }

    if (this.flickity) {
      this.flickity.on("change", () => {
        this.changeSlide();
      });

      // there is a known issue with flickity dragging on IOS, these seem to fix: https://github.com/metafizzy/flickity/issues/740#issuecomment-442202965g
      /* eslint-disable no-return-assign */
      this.flickity.on(
        "dragStart",
        () => (document.ontouchmove = (e) => e.preventDefault())
      );
      /* eslint-disable no-return-assign */
      this.flickity.on("dragEnd", () => (document.ontouchmove = () => true));
    }
  }

  /* Public methods */
  select(index) {
    this.flickity.select(index);
    this.changeSlide();
  }

  next() {
    this.flickity.next();
    this.changeSlide();
  }

  previous() {
    this.flickity.previous();
    this.changeSlide();
  }

  changeSlide() {
    this.updateIndex();
    this.updateControls();
    this.populateGalleryInfo();
    this.updateZoomModal();
  }

  showCaption() {
    const currentCaption = this.currentSlide.querySelector(
      ".js-carousel-caption"
    );
    this.captionWrapper.innerHTML = currentCaption.innerHTML;
    this.captionWrapper.classList.remove(this.classes.CAPTION_HIDDEN);
  }

  hideCaption() {
    this.captionWrapper.innerHTML = "";
    this.captionWrapper.classList.add(this.classes.CAPTION_HIDDEN);
  }

  showInfo() {
    this.infoWrapper.classList.remove(this.classes.INFO_HIDDEN);

    document.addEventListener("click", (e) => {
      if (!this.infoWrapper.contains(e.target)) {
        this.hideInfo();
      }
    });
  }

  hideInfo() {
    this.infoWrapper.classList.add(this.classes.INFO_HIDDEN);
  }

  scrollInfoWrapper(e) {
    this.scrollSpacer.scrollTop += e.deltaY;
  }

  populateGalleryInfo() {
    if (this.infoWrapper) {
      this.infoWrapper.classList.add(this.classes.INFO_TRANSITIONING);
      const currentGalleryInfo = this.currentSlide.querySelector(
        ".js-carousel-info__feed"
      );

      this.infoWrapper.innerHTML = currentGalleryInfo.innerHTML;

      setTimeout(() => {
        this.infoWrapper.classList.remove(this.classes.INFO_TRANSITIONING);
        const g = new GalleryInfo(); // eslint-disable-line no-unused-vars
      }, 1000);
    }
  }

  // TODO: GET THIS WORKING
  // resizeGallery() {
  // const slides = this.carouselWrapper.querySelectorAll(
  //   ".js-carousel-slide-wrapper"
  // );
  //
  // let slideWidth = Math.ceil(slides[0].getBoundingClientRect().width);
  // while (slideWidth % 2 !== 0) {
  //   slideWidth += 1;
  // }
  //
  // slides.forEach((slide) => {
  //   slide.style.width = slideWidth;
  // });
  // }

  destroy() {
    this.flickity.destroy();
    Carousel.current = Carousel.current.filter((c) => c !== this);
  }

  refreshLayout() {
    this.flickity.resize();
  }

  /* Public helper methods */
  static getInstance(element) {
    return Carousel.current.find((i) => {
      return i.element === element || i.flickity === element;
    });
  }

  static refreshAll() {
    Carousel.current.forEach((i) => i.refreshLayout());
  }

  static destroyAll() {
    Carousel.current.forEach((i) => i.destroy());
  }

  /* State management functions */
  update(update) {
    this.state = Object.assign(this.state, update);
    this.render(update);
  }

  updateIndex() {
    const index = this.flickity.selectedIndex;
    this.update({ index });
  }

  updateControls() {
    const canGoPrevious = typeof this.previousIndex === "number";
    const canGoNext = typeof this.nextIndex === "number";
    this.update({ canGoPrevious, canGoNext });
  }

  updateZoomModal() {
    this.update({ refreshZoomModal: true });
  }

  /* Render functions */
  render(update) {
    if (
      update.hasOwnProperty("index") ||
      update.hasOwnProperty("canGoNext") ||
      update.hasOwnProperty("canGoPrevious")
    ) {
      this.renderControls();
    }

    if (this.state.zoomable && update.hasOwnProperty("refreshZoomModal")) {
      this.zoomModal.destroy();
      this.zoomModal = new ArtworkZoomModal(this);
    }
  }

  renderControls() {
    this.renderPreviousControl();
    this.renderNextControl();
    this.announcements.innerHTML = `Now viewing slide #${
      this.state.index + 1
    } of ${this.state.maxIndex + 1}`;
  }

  renderPreviousControl() {
    if (!this.previousControl) {
      return;
    }
    if (this.state.canGoPrevious) {
      this.previousControl.removeAttribute("disabled");
    } else {
      this.previousControl.setAttribute("disabled", "");
    }
    this.previousLabel.innerHTML = `Go to slide #${this.previousIndex + 1}`;
  }

  renderNextControl() {
    if (!this.nextControl) {
      return;
    }
    if (this.state.canGoNext) {
      this.nextControl.removeAttribute("disabled");
    } else {
      this.nextControl.setAttribute("disabled", "");
    }
    this.nextLabel.innerHTML = `Go to slide #${this.nextIndex + 1}`;
  }

  /* Helper functions */
  /* eslint-disable class-methods-use-this */
  get defaultConfig() {
    return {
      wrapAround: !this.options.wrapDisabled,
      setGallerySize: false,
      prevNextButtons: false,
      pageDots: false,
      draggable: this.options.draggable,
      freeScroll: this.options.freeScroll,
      contain: false,
      cellAlign: this.options.cellAlignment,
    };
  }

  get previousIndex() {
    if (this.config.wrapAround) {
      return Util.loopAround(this.index - 1, 0, this.state.maxIndex);
    }
    if (this.state.index === 0) {
      return false;
    }
    return this.state.index - 1;
  }

  get nextIndex() {
    if (this.config.wrapAround) {
      return Util.loopAround(this.index + 1, 0, this.state.maxIndex);
    }
    if (this.state.index === this.state.maxIndex) {
      return false;
    }
    return this.state.index + 1;
  }

  get currentSlide() {
    return this.flickity.slides[this.state.index].cells[0].element;
  }
}

// NOTE: we give these listeners a name so that they don't accumulate
App.addEventListener("turbolinks:before-cache", {
  name: "carousel-destroyer",
  handler: () => {
    Carousel.destroyAll();
  },
});

App.addEventListener("resize", {
  name: "carousel-resizer",
  handler: () => {
    Carousel.refreshAll();
  },
});

Carousel.current = [];

export const init = () => {
  App.addEventListener("pageLoad", (e) => {
    [...e.target.querySelectorAll(".js-carousel")].map(
      (instance) => new Carousel(instance)
    );
  });
};
