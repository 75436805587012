export const loopAround = (val, min, max) => {
  if (val > max) {
    return min;
  }
  if (val < min) {
    return max;
  }
  return val;
};

export const getFilepickerUrl = (baseUrl, transformations) => {
  const pathParts = baseUrl.split("/");
  const imgID = pathParts.pop();
  const transformationsEncoded =
    typeof transformations === "string"
      ? transformations
      : transformations.join("/");
  const urlPath = pathParts.concat(transformationsEncoded, imgID).join("/");
  return urlPath.toString();
};

export const clamp = (val, min, max) => {
  return Math.min(Math.max(val, min), max);
};

export const loadImage = (src, next) => {
  const img = new Image();
  img.onload = next(img);
  img.src = src;
};

export const matrixToArray = (str) => {
  return str.split("(")[1].split(")")[0].split(",");
};

export const getCSRFToken = () => {
  const csrfToken = document.querySelector("[name='csrf-token']");

  if (csrfToken) {
    return csrfToken.content;
  }

  return null;
};

export const toCamelCase = (str) => {
  return str
    .replace(/\.?([A-Z])/g, function (x, y) {
      return `_${y.toLowerCase()}`;
    })
    .replace(/^_/, "");
};
