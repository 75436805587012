import app from "../nad_app";

export const init = () => {
  app.addEventListener("pageLoad", (e) => {
    const button = e.target.querySelector(".js-interview-page-load-more");
    if (!button) {
      return;
    }

    button.addEventListener("click", () => {
      const hiddenCards = document.querySelectorAll(".js-interview-card");
      hiddenCards.forEach((card) => {
        card.classList.remove("interview-card--hidden");
      });

      button.remove();
    });
  });
};
