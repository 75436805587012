import * as Util from "../utilities";

export class ArtworkZoom {
  constructor(modal, baseUrl, options = {}) {
    this.maxZoom = options.maxZoom || 3;
    this.availableWidths = [720, 1440, 2880, 5760];
    this.widestWidth = this.availableWidths.slice().pop();
    this.classes = {
      IS_ZOOMED: "js-artwork-zoom--is-zoomed",
    };

    this.modal = modal;

    // Elements
    this.setElements(this.modal);

    // Establish default state
    this.state = this.defaultState;

    // Establish layout state
    // NOTE: we receive the unzoom image dimensions from the ArtworkZoomModal because the <img> element uses object-fit, and we cannot rely on that element to have dimensions that describe image's content
    this.state.unzoomedWidth = options.unzoomedWidth;
    this.state.unzoomedHeight = options.unzoomedHeight;
    this.setLayoutState();

    // EVENTS
    this.setupEvents();

    // Initial render
    this.render(this.state);
  }

  setElements(modal) {
    this.elements = {};
    this.elements.root = modal.elements.root;
    // // this.elements.currentSlide = carousel.currentSlide;
    this.elements.zoomOutControl = this.elements.root.querySelector(
      ".js-artwork-zoom__zoom-out"
    );
    this.elements.zoomInControl = this.elements.root.querySelector(
      ".js-artwork-zoom__zoom-in"
    );
    this.elements.unZoomedImg = this.elements.root.querySelector(
      ".js-artwork-zoom__unzoomed-img"
    );
    this.elements.zoomedImg = this.elements.root.querySelector(
      ".js-artwork-zoom__zoomed-img"
    );
    this.elements.zoomContainer = this.elements.root.querySelector(
      ".js-artwork-zoom__zoom-container"
    );
    this.elements.panControl = this.elements.root.querySelector(
      ".js-artwork-zoom__pan-control"
    );
  }

  get defaultState() {
    return {
      zoom: 1,
      isZoomed: false,
      canZoomIn: true,
      canZoomOut: false,
      pointOfFocus: [0.5, 0.5],
      zoomedImgSrc: "",
      zoomedImg: null,
      imgBaseUrl: this.modal.state.baseImageUrl,
    };
  }

  setLayoutState() {
    const update = {};

    update.effectiveWidth = this.state.unzoomedWidth * this.state.zoom;
    update.effectiveHeight = this.state.unzoomedHeight * this.state.zoom;

    const zoomContainerRect =
      this.elements.zoomContainer.getBoundingClientRect();

    update.zoomContainerWidth = zoomContainerRect.width;
    update.zoomContainerHeight = zoomContainerRect.height;

    if (this.elements.panControl) {
      const panControlRect = this.elements.panControl.getBoundingClientRect();
      update.panControlWidth = panControlRect.width;
      update.panControlHeight = panControlRect.height;

      update.panControlOffsetX = (window.innerWidth - panControlRect.width) / 2;
      update.panControlOffsetY =
        (window.innerHeight - panControlRect.height) / 2;
    }

    this.update(update);
  }

  setupEvents() {
    this.resizeHandler = this.handleResize.bind(this);
    window.addEventListener("resize", this.resizeHandler);

    if (this.elements.zoomInControl) {
      this.zoomInHandler = this.zoomIn.bind(this);
      this.elements.zoomInControl.addEventListener("click", this.zoomInHandler);
    }
    if (this.elements.zoomOutControl) {
      this.zoomOutHandler = this.zoomOut.bind(this);
      this.elements.zoomOutControl.addEventListener(
        "click",
        this.zoomOutHandler
      );
    }
    if (this.elements.panControl) {
      this.mouseMoveHandler = this.handleMouseMove.bind(this);
      this.elements.panControl.addEventListener(
        "mousemove",
        this.mouseMoveHandler
      );
    }
  }

  getZoomedImgSrc(scale) {
    const wouldBeWidth = this.state.unzoomedWidth * scale;
    const widthToRequest =
      this.availableWidths.find(function (a) {
        return wouldBeWidth <= a;
      }) || this.widestWidth;

    const resizeTransformation = `resize=width:${widthToRequest},fit:max`;
    const formatTransformation = "output=f:jpg,background:white";
    const transformationString = [
      resizeTransformation,
      formatTransformation,
    ].join("/");
    return Util.getFilepickerUrl(this.state.imgBaseUrl, transformationString);
  }

  /* Public methods */
  zoom(target) {
    this.refreshLayout();

    const { zoom } = target;

    if (zoom > 1) {
      const zoomedImgSrc = this.getZoomedImgSrc(zoom);
      const imgSrcChanged = zoomedImgSrc !== this.state.zoomedImgSrc;
      const effectiveWidth = this.state.unzoomedWidth * zoom;
      const effectiveHeight = this.state.unzoomedHeight * zoom;
      const update = {
        zoom,
        zoomedImgSrc,
        effectiveWidth,
        effectiveHeight,
        isZoomed: true,
        canZoomOut: true,
        canZoomIn: zoom < this.maxZoom,
      };
      this.update(update);

      if (imgSrcChanged) {
        Util.loadImage(zoomedImgSrc, (img) => {
          this.update({
            zoomedImg: img,
          });
        });
      }
    } else {
      this.update({
        zoom,
        isZoomed: false,
        canZoomOut: false,
        canZoomIn: true,
      });
    }
  }

  zoomIn() {
    return this.zoom({ zoom: this.state.zoom + 1 });
  }

  zoomOut() {
    return this.zoom({ zoom: this.state.zoom - 1 });
  }

  getPointOfFocus(e) {
    // NOTE: we derive x & y this way because we cannot guarantee that e.target will be the panControl itself. It may be a child thereof.
    const x = e.clientX - this.state.panControlOffsetX;
    const y = e.clientY - this.state.panControlOffsetY;
    return [x / this.state.panControlWidth, y / this.state.panControlHeight];
  }

  getTransformation(args) {
    const pointOfFocus = args.pointOfFocus || this.state.pointOfFocus;
    const x = pointOfFocus[0];
    const y = pointOfFocus[1];

    this.refreshLayout();

    const { zoomContainerWidth } = this.state;
    const { zoomContainerHeight } = this.state;

    const { effectiveWidth } = this.state;
    const { effectiveHeight } = this.state;

    const translateX =
      effectiveWidth > zoomContainerWidth
        ? (effectiveWidth - zoomContainerWidth) * -x
        : zoomContainerWidth / 2 - effectiveWidth / 2;
    const translateY =
      effectiveHeight > zoomContainerHeight
        ? (effectiveHeight - zoomContainerHeight) * -y
        : zoomContainerHeight / 2 - effectiveHeight / 2;

    const translation = `translate(${translateX}px, ${translateY}px)`;
    return translation;
  }

  pan(pointOfFocus) {
    this.update({
      pointOfFocus,
    });
  }

  reset() {
    this.zoom({ zoom: 1 });
    this.pan([0.5, 0.5]);
  }

  refreshLayout() {
    this.setLayoutState();
  }

  handleResize() {
    this.refreshLayout();
  }

  handleMouseMove(e) {
    this.pan(this.getPointOfFocus(e));
  }

  removeEventListeners() {
    window.removeEventListener("resize", this.resizeHandler);

    if (this.elements.zoomInControl) {
      this.elements.zoomInControl.removeEventListener(
        "click",
        this.zoomInHandler
      );
    }
    if (this.elements.zoomOutControl) {
      this.elements.zoomOutControl.removeEventListener(
        "click",
        this.zoomOutHandler
      );
    }
    if (this.elements.panControl) {
      this.elements.panControl.removeEventListener(
        "mousemove",
        this.mouseMoveHandler
      );
    }
  }

  destroy() {
    this.removeEventListeners();
    this.elements = {};
  }

  /* State management functions */
  update(update) {
    this.state = Object.assign(this.state, update);
    this.render(update);
  }

  render(update) {
    if (update.hasOwnProperty("isZoomed")) {
      if (update.isZoomed) {
        this.elements.root.classList.add(this.classes.IS_ZOOMED);
      } else {
        this.elements.root.classList.remove(this.classes.IS_ZOOMED);
      }
    }

    if (update.hasOwnProperty("zoomedImg") && update.zoomedImg) {
      const { zoomedImg } = update;
      // The newly loaded <img> should have the same classes in case there is CSS targeting it
      zoomedImg.className = this.elements.zoomedImg.className;
      // Size the image at whatever size to be the requested scale, even if the image itself is larger/smaller than that
      zoomedImg.style.width = `${this.state.effectiveWidth}px`;
      // Swap out the existed <img> with the one we just loaded
      this.elements.zoomedImg.replaceWith(zoomedImg);
      // Update the instance's reference to the node
      this.elements.zoomedImg = zoomedImg;
    }

    if (update.hasOwnProperty("canZoomOut")) {
      if (update.canZoomOut) {
        this.elements.zoomOutControl.disabled = false;
      } else {
        this.elements.zoomOutControl.disabled = true;
      }
    }

    if (update.hasOwnProperty("canZoomIn")) {
      if (update.canZoomIn) {
        this.elements.zoomInControl.disabled = false;
      } else {
        this.elements.zoomInControl.disabled = true;
      }
    }

    // Many changes require a `pan` so that there is not jumping when the mouse moves
    if (
      update.hasOwnProperty("zoomedImg") ||
      update.hasOwnProperty("isZoomed") ||
      (update.pointOfFocus && this.state.isZoomed)
    ) {
      const transformation = this.getTransformation(update);
      this.elements.zoomedImg.style.transform = transformation;
    }

    if (update.hasOwnProperty("effectiveWidth")) {
      this.elements.zoomedImg.style.width = `${this.state.effectiveWidth}px`;
    }
  }
}
