// Modals
//
// To initialize a Modal you need three things:
// * Element with an id such as `id="newsletter-modal"` and `class="js-modal"`
// * Element that opens the modal with `class="js-modal-open"` and `data-id="newsletter-modal"` (matching the id from above)
// * Element that closes the modal with `class="js-modal-close"`
//   - Note: the closing element does not need the data-id, but it must be within the main modal element

import app from "../nad_app";
import * as Util from "../utilities";

export class Modal {
  static async postData(url = "", data = {}) {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "X-CSRF-Token": Util.getCSRFToken(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  constructor(element) {
    // classes
    this.classes = {
      MODAL_HIDDEN: "hidden",
      MODAL_OPEN: "modal--open",
      MODAL_RESPONSE: "modal__response",
    };

    // elements
    this.modal = element;
    this.modalId = element.id;
    this.modalTrigger = document.querySelectorAll(
      `.js-modal-open[data-id="${this.modalId}"]`
    );
    this.modalClose = element.querySelector(".js-modal-close");
    this.modalResponse = element.querySelector(".js-modal-response");

    // state
    this.state = {
      modalOpen: false,
    };

    // listeners
    this.modalTrigger.forEach((item) => {
      item.addEventListener("click", (e) => {
        e.preventDefault();
        this.toggleModal();
      });
    });

    this.modalClose.addEventListener("click", () => {
      this.toggleModal();
    });

    this.modal.addEventListener("submit", (e) => {
      e.preventDefault();
      const url = this.modal.getAttribute("data-form-submit");
      const params = { newsletter_sign_up: {} };
      const data = new FormData(event.target);

      // eslint-disable-next-line no-restricted-syntax
      for (const [name, value] of data) {
        // simple form is returning params as "newsletter_sign_up[param]", this structures the string to just "param"
        const key = name
          .replace("newsletter_sign_up", "")
          .replace(/[\[\]']+/g, ""); // eslint-disable-line no-useless-escape

        params.newsletter_sign_up[key] = value;
      }

      Modal.postData(url, params).then((response) => {
        // TODO: REFACTOR THIS UGLINESS
        this.modalResponse.classList.remove(
          `${this.classes.MODAL_RESPONSE}--ok`
        );
        this.modalResponse.classList.remove(
          `${this.classes.MODAL_RESPONSE}--error`
        );

        this.modalResponse.innerHTML = response.message;
        this.modalResponse.classList.add(
          `${this.classes.MODAL_RESPONSE}--${response.status}`
        );

        if (response.status === "ok") {
          setTimeout(() => {
            this.closeModal();
          }, 3000);
        }
      });
    });
  }

  closeModal() {
    this.update({ modalOpen: false });
  }

  toggleModal() {
    this.update({ modalOpen: !this.state.modalOpen });
  }

  triggerModalOpen() {
    document.dispatchEvent(this.modalOpenEvent);
  }

  triggerModalClose() {
    document.dispatchEvent(this.modalCloseEvent);
  }

  update(update) {
    this.state = Object.assign(this.state, update);
    this.render(update);
  }

  render(update) {
    if (update.hasOwnProperty("modalOpen")) {
      this.renderModal();
    }
  }

  renderModal() {
    if (this.state.modalOpen === true) {
      this.modal.classList.remove(this.classes.MODAL_HIDDEN);
      document.body.classList.add(this.classes.MODAL_OPEN);
    } else {
      this.modal.classList.add(this.classes.MODAL_HIDDEN);
      document.body.classList.remove(this.classes.MODAL_OPEN);
    }
  }
}

export const init = () => {
  app.addEventListener("turbolinks:load", {
    name: "modal-initialization",
    handler: () => {
      [...document.querySelectorAll(".js-modal")].map((ele) => new Modal(ele));
    },
  });
};
