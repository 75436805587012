import { ArtworkZoom } from "./artwork_zoom";

export class ArtworkZoomModal {
  constructor(carousel) {
    // classes
    this.classes = {
      MODAL_HIDDEN: "hidden",
      MODAL_OPEN: "modal--open",
    };

    // elements
    this.setElements(carousel);

    // state
    this.state = {
      modalOpen: false,
      unZoomedImgSrc: "",
    };

    // events
    this.setupEvents();
  }

  setElements(carousel) {
    this.elements = {};

    // carousel
    this.elements.root = carousel.element;
    this.elements.currentSlide = carousel.currentSlide;
    this.elements.carouselImage = this.elements.currentSlide.querySelector(
      ".js-artwork-carousel__image"
    );

    // modal
    this.elements.modal = carousel.element.querySelector(
      ".js-artwork-zoom-modal"
    );

    if (this.elements.modal) {
      this.elements.modalImageWrapper = this.elements.modal.querySelector(
        ".js-artwork-zoom-modal__image"
      );
      this.elements.modalImage = this.elements.modalImageWrapper.querySelector(
        ".js-artwork-zoom__unzoomed-img"
      );
      this.elements.modalTrigger = carousel.element.querySelector(
        `.js-artwork-zoom-modal__open[data-id="${this.elements.modal.id}"]`
      );
      this.elements.modalClose = this.elements.modal.querySelector(
        ".js-artwork-zoom-modal__close"
      );
    }

    // artwork
  }

  setupEvents() {
    if (this.elements.modalTrigger) {
      this.openModalHandler = this.openModal.bind(this);
      this.elements.modalTrigger.addEventListener(
        "click",
        this.openModalHandler
      );
    }

    if (this.elements.modalClose) {
      this.closeModalHandler = this.closeModal.bind(this);
      this.elements.modalClose.addEventListener(
        "click",
        this.closeModalHandler
      );
    }
  }

  openModal() {
    this.update({
      modalOpen: true,
      unZoomedImgSrc: this.elements.carouselImage.src,
      baseImageUrl: this.elements.carouselImage.getAttribute(
        "data-image-base-url"
      ),
    });
  }

  closeModal() {
    this.update({ modalOpen: false });
  }

  update(update) {
    this.state = Object.assign(this.state, update);
    this.render(update);
  }

  render(update) {
    if (update.hasOwnProperty("modalOpen")) {
      this.renderModal();
    }

    if (update.hasOwnProperty("unZoomedImgSrc")) {
      this.renderUnzoomedImage().then((unzoomedDimensions) => {
        this.initializeZoom(unzoomedDimensions);
      });
    }
  }

  renderModal() {
    if (this.state.modalOpen === true) {
      this.elements.modal.classList.remove(this.classes.MODAL_HIDDEN);
      document.body.classList.add(this.classes.MODAL_OPEN);
    } else {
      this.elements.modal.classList.add(this.classes.MODAL_HIDDEN);
      document.body.classList.remove(this.classes.MODAL_OPEN);
      this.clearImage();
      this.artworkZoom.destroy();
    }
  }

  renderUnzoomedImage() {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve({
          unzoomedWidth: img.width,
          unzoomedHeight: img.height,
        });
      };
      img.src = this.state.unZoomedImgSrc;
      this.elements.modalImage.src = this.state.unZoomedImgSrc;
    });
  }

  initializeZoom(unzoomedDimensions) {
    this.artworkZoom = new ArtworkZoom(this, null, unzoomedDimensions);
  }

  removeEventListeners() {
    if (this.elements.modalTrigger) {
      this.elements.modalTrigger.removeEventListener(
        "click",
        this.openModalHandler
      );
    }

    if (this.elements.modalClose) {
      this.elements.modalClose.removeEventListener(
        "click",
        this.closeModalHandler
      );
    }
  }

  clearImage() {
    this.state.unZoomedImgSrc = "";
    this.elements.modalImage.src = "";
  }

  destroy() {
    if (this.artworkZoom) {
      this.artworkZoom.destroy();
    }
    this.clearImage();
    this.removeEventListeners();
    this.elements = {};
  }
}
